import { graphql, HeadProps, PageProps } from 'gatsby'
import React from 'react'

import ClientLogos from '../components/ClientLogos'
import FeatureOverview from '../components/FeatureOverview'
import Hero from '../components/Hero'
import IndustriesOverview from '../components/IndustriesOverview'
import Layout from '../components/Layout'
import RelatedResources from '../components/RelatedResources'
import RelatedSolutions from '../components/RelatedSolutions'
import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupBreadcrumb } from '../components/SchemaMarkup/SchemaMarkupBreadcrumb'
import TrainstopSection from '../components/TrainstopSection'

const IndustryOverviewPage = (
  props: PageProps<Queries.IndustryOverviewPageQuery>,
) => {
  const pageData = props?.data?.contentstackPageIndustryOverview
  const breadcrumbLinks = [{ text: pageData?.title || '' }]
  const clientStoriesUrl = props?.data?.clientStoriesUrl?.url || '/'

  let resourcesByFilter
  switch (props.pageContext.filterType) {
    case 'Contentstack_topic_resource_type':
      resourcesByFilter = [
        ...props?.data?.resourceCardsByResourceTypeFilter?.nodes,
      ]
      break
    case 'Contentstack_topic_industry':
      resourcesByFilter = [...props?.data?.resourceCardsByIndustryFilter?.nodes]
      break
    case 'Contentstack_topic_role':
      resourcesByFilter = [...props?.data?.resourceCardsByRoleFilter?.nodes]
      break
    default:
      resourcesByFilter = []
      break
  }

  const allFeaturedResourceCards =
    props?.data?.allFeaturedResourceCards?.nodes || []
  if (resourcesByFilter.length === 0) {
    allFeaturedResourceCards?.forEach((resource: any) =>
      resourcesByFilter.push(resource),
    )
  }

  const metaTitle =
    pageData?.metadata?.page_title ||
    pageData?.title ||
    pageData?.page_header?.[0]?.headline ||
    pageData?.page_header?.[0]?.topic?.[0]?.headline
  const metaDesc =
    pageData?.metadata?.description ||
    pageData?.page_header?.[0]?.subheading ||
    pageData?.page_header?.[0]?.topic?.[0]?.subheading
  const metaImg =
    pageData?.metadata?.image ||
    pageData?.page_header?.[0]?.feature_image ||
    pageData?.page_header?.[0]?.topic?.[0]?.feature_image

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      description={metaDesc}
      image={metaImg}
      locale={pageData?.publish_details?.locale}
      slug={props.pageContext.slug}
      title={metaTitle}
    >
      <Hero content={pageData?.page_header?.[0]} />
      {pageData?.introduction?.[0] ? (
        <FeatureOverview
          body={
            pageData?.introduction?.[0]?.overview_description ||
            pageData?.introduction?.[0]?.topic?.[0]?.overview_description
          }
          headline={
            pageData?.introduction?.[0]?.headline ||
            pageData?.introduction?.[0]?.topic?.[0]?.overview_headline
          }
          theme="Dark"
        />
      ) : null}
      {pageData?.client_logos?.[0] ? (
        <ClientLogos content={pageData?.client_logos?.[0]} />
      ) : null}
      {pageData?.industries?.[0] ? (
        <IndustriesOverview
          industries={pageData?.industries?.[0]?.industries}
          primaryCtaText={pageData?.industries?.[0]?.primary_cta_text}
          secondaryCtaText={pageData?.industries?.[0]?.secondary_cta_text}
          secondaryCtaUrl={clientStoriesUrl}
        />
      ) : null}
      {pageData?.featured_programs?.[0] ? (
        <RelatedSolutions relatedSolutions={pageData?.featured_programs?.[0]} />
      ) : null}
      {pageData?.train_stop?.[0] ? (
        <TrainstopSection
          ctaText={pageData?.train_stop?.[0]?.cta_text ?? undefined}
          headline={pageData?.train_stop?.[0]?.headline ?? undefined}
          isHeroTrainStop={
            pageData?.train_stop?.[0]?.is_hero_train_stop ?? undefined
          }
          slides={pageData?.train_stop?.[0]?.slides ?? []}
        />
      ) : null}
      {pageData?.related_resources?.[0] ? (
        <RelatedResources
          content={{
            related_resources: resourcesByFilter || [],
            ...pageData?.related_resources?.[0],
          }}
        />
      ) : null}
    </Layout>
  )
}

export const Head = ({
  data,
}: HeadProps<Queries.IndustryOverviewPageQuery>) => (
  <>
    <SchemaMarkup data={data.contentstackPageIndustryOverview?.schema_markup} />
    <SchemaMarkupBreadcrumb
      pageTitle={data?.contentstackPageIndustryOverview?.title}
    />
  </>
)

export default IndustryOverviewPage

export const data = graphql`
  query IndustryOverviewPage(
    $pageId: String
    $locale: String
    $filterId: String
  ) {
    contentstackPageIndustryOverview(
      id: { eq: $pageId }
      publish_details: { locale: { eq: $locale } }
    ) {
      id
      title
      locale
      chinese_version_url
      publish_details {
        locale
      }
      metadata {
        page_title
        description
        image {
          description
          url
        }
      }
      schema_markup {
        ...SchemaMarkup
      }
      page_header {
        ...PageHeaderSection
      }
      introduction {
        headline
        overview_description
      }
      client_logos {
        ...ClientLogos
      }
      industries {
        primary_cta_text
        secondary_cta_text
        industries {
          id
          url
          industry {
            id
            headline
            short_description
            feature_image_card {
              description
              imgixImage {
                gatsbyImageData(width: 650, imgixParams: { q: 65 })
              }
            }
          }
        }
      }
      featured_programs {
        ...FeaturedProgramsSection
      }
      train_stop {
        ...TrainStopSection
      }
      related_resources {
        ...RelatedResourcesSection
      }
    }

    resourceCardsByRoleFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { roles: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByResourceTypeFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { resource_type: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByIndustryFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { industry: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    allFeaturedResourceCards: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: { elemMatch: { is_featured: { eq: "Yes" } } }
      }
      sort: { fields: resource___publication_date, order: DESC }
      limit: 15
    ) {
      nodes {
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    clientStoriesUrl: contentstackPageClientStoriesOverview(
      publish_details: { locale: { eq: $locale } }
    ) {
      url
    }
  }
`
