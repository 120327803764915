import React from 'react'

import TrainingProgramCategory from '../TrainingProgramCategory'
import { IndustriesOverviewSection } from './styles'

interface IndustriesOverviewProps {
  industries: any[]
  primaryCtaText: string
  secondaryCtaText: string
  secondaryCtaUrl: string
}

const IndustriesOverview = (props: IndustriesOverviewProps) => {
  const { industries, primaryCtaText, secondaryCtaText, secondaryCtaUrl } =
    props

  return (
    <IndustriesOverviewSection>
      {industries?.map((industry: any, i: number) => (
        <TrainingProgramCategory
          category={industry.industry[0]}
          gridClass={i % 2 === 0 ? 'imageRight' : 'imageLeft'}
          key={industry.id}
          primaryCtaText={primaryCtaText}
          primaryUrl={industry.url}
          secondaryCtaText={secondaryCtaText}
          secondaryUrl={secondaryCtaUrl}
        />
      ))}
    </IndustriesOverviewSection>
  )
}

export default IndustriesOverview
