import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const IndustriesOverviewSection = styled.section`
  background-color: ${rspTheme.palette.primary.background};
  padding: 0 0 96px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 0 0 60px;
  }
`
